<template>
  <div id="layout">
    <lay-header></lay-header>
    <main>
      <lay-menu :menulist="menulist"></lay-menu>
      <div class="views">
        <transition enter-active-class="animate__animated animate__fadeInRight">
          <router-view :key="$route.fullPath">路由展示区</router-view>
        </transition>
      </div>
    </main>
  </div>
</template>


<script>
import { menulist } from "@/db/layout/menu.js";
import layMenu from "./lay-menu.vue";
import layHeader from "./lay-header.vue";
export default {
  components: { layMenu, layHeader },
  data() {
    return {
      menulist,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/theme";
#layout {
  height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  height: calc(100% - 60px);
  display: flex;
}

.views {
  width: calc(100% - 200px);
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  border: 15px solid $bg-color-padding;
}
</style>