<template>
  <div id="lay-header">
    <div class="logo">简 家</div>
    <div class="title">
      <div class="title-L">
        <span class="active">我的任务</span>
      </div>
      <div class="title-R">
        <el-dropdown>
          <div class="active myinfo">
            <el-avatar
              size="small"
              :src="
                (userInfo.userAvatarUrl && userInfo.userAvatarUrl.filePath) ||
                userAvatar
              "
            ></el-avatar>
            <span style="color: #c0c4cc">丨</span>
            <span>{{ userInfo.userName }}</span>
            <i class="el-icon-caret-bottom"></i>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="link">个人资料</el-dropdown-item>
            <el-dropdown-item @click.native="loginOut"
              >退出登录</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { myUserInfo } from "@/api/setting/userlist";
export default {
  data() {
    return {
      userAvatar:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      userInfo: {},
    };
  },
  mounted() {
    this.myUserInfo();
  },
  methods: {
    myUserInfo() {
      myUserInfo().then((res) => {
        this.userInfo = res.data;
      });
    },
    // 跳转我的资料
    link() {
      if (this.$route.path != "/setting/userinfo") {
        this.$router.push({
          path: "/setting/userinfo",
        });
      }
    },

    // 退出登录
    loginOut() {
      window.localStorage.removeItem("yu_token");
      this.$router.push({
        path: "/mine/login",
      });
      this.$message({
        message: "已退出",
        type: "warning",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#lay-header {
  height: 60px;
  display: flex;
}

.logo {
  width: 200px;
  background: #3b466a;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 24px;
}

.title {
  flex: 1;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  box-shadow: 1px 2px 4px rgba(0, 21, 41, 0.16);
  z-index: 1;
  height: 100%;
}

.title-R,
.title-L {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-R > .el-dropdown {
  height: 100%;
}

.active {
  height: 100%;
  display: flex;
  padding: 0 24px;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
}

.active:hover {
  background: rgba(0, 0, 0, 0.1);
}

.myinfo {
  font-size: 0.16rem;
}
</style>