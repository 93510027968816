<template>
  <div id="lay-menu">
    <el-menu
      :unique-opened="uniqueOpened"
      :default-openeds="openeds"
      :default-active="defaultActive"
      text-color="#fff"
      background-color="#3B466A"
      active-text-color="#409EFF"
      router
    >
      <el-submenu
        v-for="(item, index) in menulist"
        :key="index"
        :index="String(index)"
        v-show="userInfo.userAuth >= item.showAuth"
      >
        <template #title>
          <i :class="item.icon"></i>
          <span>{{ item.menuName }}</span>
        </template>
        <el-menu-item
          v-for="i in item.secMenu"
          :key="i.path"
          :index="String(i.path)"
          v-show="userInfo.userAuth >= i.showAuth"
          >{{ i.menuName }}</el-menu-item
        >
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
import { myUserInfo } from "@/api/setting/userlist";
export default {
  props: ["menulist"],
  data() {
    return {
      openeds: ["0"], //下拉菜单默认展开项
      defaultActive:"/home/todolist",
      uniqueOpened: true, //是否开启手风琴模式
      userInfo: "",
    };
  },
  created() {
    this.myUserInfo();
  },
  methods: {
    // 【请求】我的资料详情
    myUserInfo() {
      myUserInfo().then((res) => {
        this.userInfo = res.data;
      });
    },
  },
};
</script>


<style lang="scss" scoped>
#lay-menu {
  width: 200px;
}

.el-menu {
  height: 100%;
  border: 0;
}
</style>