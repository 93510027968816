export const menulist = [
  // 我的主页
  {
    icon: "el-icon-s-home",
    menuName: "我的主页",
    showAuth: 1,
    secMenu: [{
        menuName: "待办事项",
        path: "/home/todolist",
        showAuth: 1
      },
      {
        menuName: "物品收纳",
        path: "/home/goodslist",
        showAuth: 1
      }
    ]
  },
  // 系统管理
  {
    icon: "el-icon-s-tools",
    menuName: "系统管理",
    showAuth: 1,
    secMenu: [{
        menuName: "用户列表",
        path: "/setting/userlist",
        showAuth: 3
      },
      {
        menuName: "个人资料",
        path: "/setting/userinfo",
        showAuth: 1
      }
    ]
  }
]